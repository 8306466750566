// src/Home.js
import React from "react";
import MapComponent from "./components/Map/Map";

function Home() {
  return (
    <div>
      <MapComponent />
    </div>
  );
}

export default Home;
