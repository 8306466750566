import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import Map, { Layer, Source, Marker, Popup } from 'react-map-gl';
import mapboxgl from 'mapbox-gl';
import CustomMarker from './CustomMarker';
import axios from 'axios';
import * as satellite from 'satellite.js';
import TrailLayer from './TrailLayer';
import { useFetchLocations } from '../../hooks/useFetchLocations';

function MapComponent() {
  const [daysToFetch, setDaysToFetch] = useState(7);
  const { locations, loading, error, fetchMore } = useFetchLocations(daysToFetch);
  const mapRef = useRef(null);
  const [userLocation, setUserLocation] = useState(null);
  const [satellites, setSatellites] = useState([]);
  const [showSatellitePopup, setShowSatellitePopup] = useState(null);

  const [viewState, setViewState] = useState({
    longitude: 151.17778356214825,
    latitude: -33.89122355560421,
    zoom: 3, // Adjusted for better globe view
    pitch: 10,
    bearing: 0
  });

  const satelliteIds = [
    25544,  // ISS (for reference)
    43013,  // USA 282 (WGS-9) - Wideband Global SATCOM
    44481,  // USA 292 (AEHF-5) - Advanced Extremely High Frequency
    45465,  // USA 298 (NROL-101) - National Reconnaissance Office satellite
    40258,  // USA 250 (GSSAP 1) - Geosynchronous Space Situational Awareness Program
    40259,  // USA 251 (GSSAP 2) - Geosynchronous Space Situational Awareness Program
    41587,  // USA 270 (SBIRS GEO-3) - Space-Based Infrared System
    43941,  // USA 290 (SBIRS GEO-4) - Space-Based Infrared System
    44868,  // USA 294 (NROL-71) - National Reconnaissance Office satellite
    45241,  // USA 296 (AEHF-6) - Advanced Extremely High Frequency
    46265,  // USA 309 (GPS III SV04) - Global Positioning System
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchMore();
    }, 10000); // 10 seconds
    return () => clearInterval(intervalId);
  }, [fetchMore]);

  function getSunPosition(date) {
    const julianDate = (date.getTime() / 86400000) + 2440587.5;
    const julianCentury = (julianDate - 2451545) / 36525;
    const geomMeanLongSun = (280.46646 + julianCentury * (36000.76983 + julianCentury * 0.0003032)) % 360;
    const geomMeanAnomSun = 357.52911 + julianCentury * (35999.05029 - 0.0001537 * julianCentury);
    const eccentEarthOrbit = 0.016708634 - julianCentury * (0.000042037 + 0.0000001267 * julianCentury);
    const sunEqOfCtr = Math.sin(geomMeanAnomSun * Math.PI / 180) * (1.914602 - julianCentury * (0.004817 + 0.000014 * julianCentury)) +
      Math.sin(2 * geomMeanAnomSun * Math.PI / 180) * (0.019993 - 0.000101 * julianCentury) +
      Math.sin(3 * geomMeanAnomSun * Math.PI / 180) * 0.000289;
    const sunTrueLong = geomMeanLongSun + sunEqOfCtr;
    const sunAppLong = sunTrueLong - 0.00569 - 0.00478 * Math.sin((125.04 - 1934.136 * julianCentury) * Math.PI / 180);
    const obliqCorrection = 23.43929111 - julianCentury * (0.013004167 + julianCentury * (0.0000001639 + julianCentury * 0.0000005036));
    const lat = Math.asin(Math.sin(obliqCorrection * Math.PI / 180) * Math.sin(sunAppLong * Math.PI / 180)) * 180 / Math.PI;
    const lng = -((date.getUTCHours() + date.getUTCMinutes() / 60 + date.getUTCSeconds() / 3600) * 15 - 180);
    return [lng, lat];
  }

  function updateSunPosition() {
    const date = new Date();
    const sunPos = getSunPosition(date);

    map.setLights({
      main: {
        anchor: 'map',
        color: 'white',
        intensity: 0.75,
        position: [sunPos[0], sunPos[1], 80],
        type: 'flat'
      }
    });

    map.setPaintProperty('sky', 'sky-atmosphere-sun', sunPos);
  }


  function calculateTerminator(sunLng, sunLat) {
    const coordinates = [];
    for (let i = 0; i <= 360; i++) {
      const angle = i * Math.PI / 180;
      const lat = Math.asin(Math.sin(sunLat * Math.PI / 180) * Math.cos(angle) -
        Math.cos(sunLat * Math.PI / 180) * Math.sin(angle) * Math.cos(90 * Math.PI / 180)) * 180 / Math.PI;
      const lng = sunLng + Math.atan2(Math.sin(angle) * Math.sin(90 * Math.PI / 180),
        Math.cos(sunLat * Math.PI / 180) * Math.cos(angle) +
        Math.sin(sunLat * Math.PI / 180) * Math.sin(angle) * Math.cos(90 * Math.PI / 180)) * 180 / Math.PI;
      coordinates.push([lng, lat]);
    }
    return coordinates;
  }

  useEffect(() => {
    if (mapRef.current) {
      const map = mapRef.current.getMap();
      map.on('load', () => {
        map.setFog({
          'color': 'rgb(186, 210, 235)', // light blue
          'high-color': 'rgb(36, 92, 223)', // blue
          'horizon-blend': 0.02,
          'space-color': 'rgb(11, 11, 25)', // dark blue
          'star-intensity': 0.6 // Brightness of stars when zoomed out
        });

        // Add terrain
        map.addSource('mapbox-dem', {
          'type': 'raster-dem',
          'url': 'mapbox://mapbox.terrain-rgb',
          'tileSize': 512,
          'maxzoom': 14
        });
        map.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 1.5 });

        // Add sky layer
        map.addLayer({
          'id': 'sky',
          'type': 'sky',
          'paint': {
            'sky-type': 'atmosphere',
            'sky-atmosphere-sun': [0.0, 0.0],
            'sky-atmosphere-sun-intensity': 15
          }
        });

        // Add stars layer
        map.addLayer({
          'id': 'stars',
          'type': 'sky',
          'paint': {
            'sky-type': 'atmosphere',
            'sky-atmosphere-sun-intensity': 0,
            'sky-opacity': ['interpolate', ['linear'], ['zoom'], 0, 0, 5, 0.3, 7, 0]
          }
        });



        // Add day/night layer
        map.on('load', () => {
          // ... other layer definitions ...

          // Add day/night layer last
          map.addSource('daynight', {
            type: 'geojson',
            data: {
              type: 'Feature',
              geometry: {
                type: 'Polygon',
                coordinates: [
                  [[-180, -90], [180, -90], [180, 90], [-180, 90], [-180, -90]]
                ]
              }
            }
          });

          map.addLayer({
            id: 'daynight',
            type: 'fill',
            source: 'daynight',
            paint: {
              'fill-color': 'rgba(0, 0, 0, 0.5)',
              'fill-opacity': 0.5
            }
          }, 'water'); // Ensure it's above the water layer
        });


        map.setStyle({
          ...map.getStyle(),
          layers: [
            ...map.getStyle().layers,
            {
              id: 'night-overlay',
              type: 'background',
              paint: {
                'background-color': 'rgba(0, 0, 0, 0.7)',
                'background-opacity': [
                  'interpolate',
                  ['linear'],
                  ['sky-illumination-intensity'],
                  0, 0.7,
                  0.5, 0
                ]
              }
            }
          ]
        });


        function updateDayNightLayer() {
          const date = new Date();
          const sunPos = getSunPosition(date);
          const terminatorCoordinates = calculateTerminator(sunPos[0], sunPos[1]);

          map.getSource('daynight').setData({
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [terminatorCoordinates]
            }
          });

          // Update sky and light properties
          map.setPaintProperty('sky', 'sky-atmosphere-sun', sunPos);
          map.setLights({
            main: {
              anchor: 'map',
              color: 'white',
              intensity: 0.15,
              position: [sunPos[0], sunPos[1], 80],
              type: 'flat'
            }
          });
        }


        // Update the layer every minute
        updateDayNightLayer();
        setInterval(updateDayNightLayer, 60000);
      });
    }
  }, []);

  useEffect(() => {
    // Get user location
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation([position.coords.longitude, position.coords.latitude]);
        },
        (error) => {
          console.error('Error getting user location:', error);
        }
      );
    }
  }, []);

  const groupedLocations = useMemo(() => {
    return locations.reduce((acc, location) => {
      if (!acc[location.uid]) {
        acc[location.uid] = [];
      }
      acc[location.uid].push(location);
      return acc;
    }, {});
  }, [locations]);

  const handleMapMove = useCallback((evt) => {
    setViewState(evt.viewState);
  }, []);

  const handleDaysChange = (event) => {
    setDaysToFetch(Number(event.target.value));
  };

  if (error) return <div>Error: {error.message}</div>;
  if (locations.length === 0 && !loading) return <div>No locations found for the selected time range.</div>;

  return (
    <div style={{
      position: 'relative',
      height: 'calc(100vh - 64px)',
      width: '100%',
      marginTop: '10px'
    }}>
      <div style={{ position: 'absolute', top: '10px', left: '10px', zIndex: 1000, background: 'white', padding: '10px', borderRadius: '5px' }}>
        <label>
          Days to fetch: {daysToFetch}
          <input
            type="range"
            min="1"
            max="30"
            value={daysToFetch}
            onChange={handleDaysChange}
            style={{ width: '200px', marginLeft: '10px', opacity: '0.7' }}
          />
        </label>
      </div>
      <Map
        {...viewState}
        onMove={handleMapMove}
        mapboxAccessToken="pk.eyJ1IjoidGhvbWFzaGFtZXIiLCJhIjoiY20xajdxb2c2MHRraTJscTJpM2Z5anVkciJ9.VV-pnmFX35FoCTziB6_U3A"
        style={{ width: '100%', height: '90%' }}
        ref={mapRef}
        mapStyle="mapbox://styles/mapbox/dark-v11"
        projection="globe"
        mapLib={mapboxgl}
        attributionControl={false}
        terrain={{ source: 'mapbox-dem', exaggeration: 1.5 }}
        fog={{
          'color': 'rgb(186, 210, 235)',
          'high-color': 'rgb(36, 92, 223)',
          'horizon-blend': 0.02,
          'space-color': 'rgb(11, 11, 25)',
          'star-intensity': 0.6
        }}
        light={{
          'anchor': 'map',
          'color': 'white',
          'intensity': 0.15,
          'position': [0, -90, 80]
        }}

      >
        {/* <Source
          id="mapbox-dem"
          type="raster-dem"
          url="mapbox://mapbox.terrain-rgb"
          tileSize={512}
          maxzoom={14}
        />
        <Layer
          id="sky"
          type="sky"
          paint={{
            'sky-type': 'atmosphere',
            'sky-atmosphere-sun': [0.0, 0.0],
            'sky-atmosphere-sun-intensity': 15
          }}
        />
        <Layer
          id="stars"
          type="sky"
          paint={{
            'sky-type': 'atmosphere',
            'sky-atmosphere-sun-intensity': 0,
            'sky-opacity': ['interpolate', ['linear'], ['zoom'], 0, 0, 5, 0.3, 7, 0]
          }}
        /> */}
        {/* Countries layer */}
        {/* <Source
          id="countries"
          type="vector"
          url="mapbox://mapbox.country-boundaries-v1"
        >
          <Layer
            id="country-boundaries"
            type="line"
            source-layer="country_boundaries"
            paint={{
              'line-color': 'rgba(255, 255, 255, 0.5)',
              'line-width': 1
            }}
          />
        </Source> */}
        <Source
          id="daynight"
          type="geojson"
          data={{
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [
                [[-180, -90], [180, -90], [180, 90], [-180, 90], [-180, -90]]
              ]
            }
          }}
        >
          <Layer
            id="daynight"
            type="fill"
            paint={{
              'fill-color': 'rgba(0, 0, 0, 0.5)',
              'fill-opacity': [
                'interpolate',
                ['linear'],
                ['zoom'],
                0, 0.7,
                1, 0.5,
                2, 0.3
              ]
            }}
          />
        </Source>

        {/* Your custom data layers */}
        {Object.entries(groupedLocations).map(([uid, locationsForUid]) => {
          const sortedLocations = locationsForUid.sort((a, b) => b.timestamp - a.timestamp);
          const latestLocation = sortedLocations[0];
          return (
            <React.Fragment key={uid}>
              <CustomMarker location={latestLocation} zoom={viewState.zoom} />
              <TrailLayer locations={sortedLocations} />
            </React.Fragment>
          );
        })}
        {userLocation && (
          <Marker longitude={userLocation[0]} latitude={userLocation[1]} anchor="bottom">
            <div style={{ color: 'red', fontSize: '24px' }}>📍</div>
          </Marker>
        )}
      </Map>
      <div style={{ position: 'absolute', top: 10, left: 10, zIndex: 1 }}>
        <label>
          Days to fetch:
          <input type="number" value={daysToFetch} onChange={handleDaysChange} min="1" max="30" />
        </label>
      </div>
    </div>
  );
}

export default MapComponent;
