import React from "react";
import { Link, useNavigate } from "react-router-dom";

function Navbar() {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    navigate("/login");
  };

  return (
    <nav
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px 20px",
        backgroundColor: "rgb(50, 50, 50)",
      }}
    >
      <div>
        <Link
          to="/"
          style={{ textDecoration: "none", color: "black", fontSize: "1.5em" }}
        >
          Manager
        </Link>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        {user && (
          <span style={{ marginRight: "15px" }}>
            Current User: {user.username}
          </span>
        )}
        <button onClick={handleLogout}>Logout</button>
      </div>
    </nav>
  );
}

export default Navbar;
