import { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';

const api = axios.create({
  baseURL: 'https://bms.hamer.cloud',
  timeout: 30000,
});

export const useFetchLocations = (daysToFetch) => {
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const cacheRef = useRef(new Map());
  const cursorRef = useRef(null);

  const fetchLocations = useCallback(async (reset = false) => {
    if (reset) {
      cursorRef.current = null;
      setLocations([]);
    }

    setLoading(true);
    try {
      const startTimestamp = Math.floor(Date.now() / 1000) - daysToFetch * 24 * 60 * 60;
      const params = {
        limit: 1000,
        cursor: cursorRef.current,
      };

      const response = await api.get('/locations', { params });
      const { locations: newLocations, next_cursor } = response.data;

      if (newLocations.length > 0) {
        const filteredLocations = newLocations
          .filter(loc => loc.timestamp >= startTimestamp)
          .sort((a, b) => b.timestamp - a.timestamp);

        setLocations(prevLocations => {
          const combinedLocations = [...prevLocations, ...filteredLocations];
          return combinedLocations.filter(loc => loc.timestamp >= startTimestamp);
        });

        cursorRef.current = next_cursor;
        if (next_cursor && filteredLocations.length === params.limit) {
          await fetchLocations();
        }
      }

      setError(null);
    } catch (err) {
      console.error('API Error:', err);
      setError(err.message || 'An error occurred while fetching locations');
    } finally {
      setLoading(false);
    }
  }, [daysToFetch]);

  useEffect(() => {
    fetchLocations(true);
  }, [daysToFetch, fetchLocations]);

  return { locations, loading, error, fetchMore: () => fetchLocations(false) };
};
