import React from 'react';
import { Layer, Source } from 'react-map-gl';

const TrailLayer = ({ locations }) => {
  if (!locations || locations.length < 2) return null;

  const geojson = {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'LineString',
      coordinates: locations.map(loc => [loc.longitude, loc.latitude])
    }
  };

  return (
    <Source type="geojson" data={geojson}>
      <Layer
        id={`trail-${locations[0].uid}`}
        type="line"
        paint={{
          'line-color': '#88ffff',
          'line-width': 1,
          'line-opacity': 0.4
        }}
      />
    </Source>
  );
};

export default TrailLayer;
