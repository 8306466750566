import React, { useState, useEffect } from 'react';
import { Source, Layer } from 'react-map-gl';

const IsochroneLayer = ({ location, type, value, profile }) => {
  const [isochroneData, setIsochroneData] = useState(null);

  useEffect(() => {
    const fetchIsochrone = async () => {
      const contourParam = type === 'time' ? 'contours_minutes' : 'contours_meters';
      const contourValue = type === 'time' ? value : value * 1000; // Convert km to meters for distance

      const minutes = type === 'time' ? value : null;
      const kilometers = type === 'distance' ? Math.min(value, 100) : null;

      const query = `https://api.mapbox.com/isochrone/v1/mapbox/${profile}/${location.longitude},${location.latitude}?${contourParam}=${contourValue}&polygons=true&access_token=${"pk.eyJ1IjoidGhvbWFzaGFtZXIiLCJhIjoiY2x5aWVyd3ZjMGVoMjJub2EzZ2Njdm0xMiJ9.L-DtLkOrSTfiYPd9Bl-0Eg"}`;

      try {
        const response = await fetch(query);
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(`API error: ${response.status} - ${errorData.message}`);
        }
        const data = await response.json();
        setIsochroneData(data);
      } catch (error) {
        console.error('Error fetching isochrone data:', error.message);
      }
    };

    fetchIsochrone();
  }, [location, type, value, profile]);

  if (!isochroneData) return null;

  return (
    <Source type="geojson" data={isochroneData}>
      <Layer
        id={`isochrone-${location.uid}`}
        type="fill"
        paint={{
          'fill-color': 'rgba(0, 100, 255, 0.3)',
          'fill-opacity': 0.5
        }}
      />
    </Source>
  );
};

export default IsochroneLayer;
