import React, { useState } from 'react';
import { Marker } from 'react-map-gl';
import { getMarkerColor } from '../../utils/mapUtils';
import Menu from './Menu';
import IsochroneLayer from './IsochroneLayer';

const CustomMarker = ({ location, zoom }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [isochroneSettings, setIsochroneSettings] = useState({
    enabled: false,
    type: 'time',
    value: 15,
    profile: 'walking'
  });
  if (!location) {
    console.error('Location prop is undefined or null in CustomMarker');
    return null;
  }

  const { longitude, latitude, uid, timestamp, accuracy, altitude, bearing } = location;

  const markerColor = getMarkerColor(uid);
  const outlineColor = getTimestampColor(timestamp);

  // Calculate scale based on zoom level
  const minZoom = 10;
  const maxZoom = 16;
  const minScale = 0.5;
  const maxScale = 1.5;
  const scale = Math.min(Math.max((zoom - minZoom) / (maxZoom - minZoom), 0), 1) * (maxScale - minScale) + minScale;

  const baseSize = 15;
  const scaledSize = baseSize * scale;

  // Convert accuracy from meters to pixels
  const metersPerPixel = 156543.03392 * Math.cos(latitude * Math.PI / 180) / Math.pow(2, zoom);
  const accuracyInPixels = accuracy / metersPerPixel;

  const handleMarkerClick = () => {
    setShowMenu(!showMenu);
  };

  const handleIsochroneChange = (uid, type, value, enabled, profile) => {
    setIsochroneSettings({ enabled, type, value, profile });
  };

  return (
    <>
      <Marker longitude={location.longitude} latitude={location.latitude}>
        <div onClick={handleMarkerClick} style={{ cursor: 'pointer' }}>
          {/* Accuracy circle */}
          <div style={{
            position: 'absolute',
            width: `${accuracyInPixels * 2}px`,
            height: `${accuracyInPixels * 2}px`,
            borderRadius: '50%',
            backgroundColor: 'rgba(0, 0, 255, 0.1)',
            border: '1px solid rgba(0, 0, 255, 0.3)',
            transform: 'translate(-50%, -50%)',
            left: '50%',
            top: '50%'
          }} />

          <div style={{
            width: `${scaledSize}px`,
            height: `${scaledSize}px`,
            position: 'relative'
          }}>
            {/* Main marker */}
            <div style={{
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              backgroundColor: markerColor,
              border: `${2 * scale}px solid ${outlineColor}`,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              left: '50%',
              top: '50%',
              color: 'white',
              fontSize: `${5 * scale}px`,
              fontWeight: 'bold'
            }}>
              {altitude.toFixed(0)}m
            </div>

            {/* Bearing indicator */}
            <div style={{
              position: 'absolute',
              left: '50%',
              bottom: '50%',
              width: `${2 * scale}px`,
              height: `${10 * scale}px`,
              backgroundColor: 'white',
              opacity: 0.8,
              transform: `translateX(-50%) rotate(${bearing}deg)`,
              transformOrigin: 'bottom'
            }} />
          </div>
          {showMenu && (
            <Menu
              location={location}
              onClose={() => setShowMenu(false)}
              onIsochroneChange={handleIsochroneChange}
              isochroneSettings={isochroneSettings}
            />
          )}
        </div>
      </Marker>
      {isochroneSettings.enabled && (
        <IsochroneLayer
          location={location}
          type={isochroneSettings.type}
          value={isochroneSettings.value}
          profile={isochroneSettings.profile}
        />
      )}
    </>
  );
};

const getTimestampColor = (timestamp) => {
  if (!timestamp) return 'gray'; // Default color if timestamp is not provided

  const now = Date.now() / 1000; // Current time in seconds
  const diff = now - timestamp;

  // Define our time thresholds in seconds
  const recentThreshold = 600; // 10 minutes
  const midThreshold = 1800; // 30 minutes

  // Define our colors
  const colors = [
    { r: 0, g: 255, b: 0 },    // Green
    { r: 255, g: 255, b: 0 },  // Yellow
    { r: 255, g: 0, b: 0 }     // Red
  ];

  let ratio;
  if (diff <= recentThreshold) {
    // Between 0 and 10 minutes
    ratio = diff / recentThreshold;
    return interpolateColor(colors[0], colors[1], ratio);
  } else if (diff <= midThreshold) {
    // Between 10 and 30 minutes
    ratio = (diff - recentThreshold) / (midThreshold - recentThreshold);
    return interpolateColor(colors[1], colors[2], ratio);
  } else {
    // More than 30 minutes
    return `rgb(${colors[2].r}, ${colors[2].g}, ${colors[2].b})`;
  }
};

// Helper function to interpolate between two colors
const interpolateColor = (color1, color2, ratio) => {
  const r = Math.round(color1.r + (color2.r - color1.r) * ratio);
  const g = Math.round(color1.g + (color2.g - color1.g) * ratio);
  const b = Math.round(color1.b + (color2.b - color1.b) * ratio);
  return `rgb(${r}, ${g}, ${b})`;
};


export default CustomMarker;
