export const groupLocationsByUid = (locations) => {
    return locations.reduce((acc, location) => {
      if (!acc[location.uid]) {
        acc[location.uid] = [];
      }
      acc[location.uid].push(location);
      return acc;
    }, {});
  };
  
export const getMarkerColor = (uid) => {
  const colors = {
    'p': 'blue',    // people
    'c': 'green',   // cars
    'd': 'red',     // drones
    'a': 'purple',  // aircraft
    's': 'orange',  // ships
  };
  return colors[uid[0].toLowerCase()] || 'blue'; // Default to 'people' (blue) if type is not specified
};
