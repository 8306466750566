import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-input-slider';

const ToggleSwitch = ({ id, checked, onChange }) => (
  <div className="toggle-switch" onClick={(e) => e.stopPropagation()}>
    <input
      type="checkbox"
      id={id}
      checked={checked}
      onChange={(e) => {
        e.stopPropagation();
        onChange(e);
      }}
      className="toggle-switch-checkbox"
    />
    <label className="toggle-switch-label" htmlFor={id}>
      <span className="toggle-switch-inner" />
      <span className="toggle-switch-switch" />
    </label>
  </div>
);


const Menu = ({ location, onClose, onIsochroneChange, isochroneSettings }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isTypeDropdownOpen, setIsTypeDropdownOpen] = useState(false);
  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    setIsVisible(true);

    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsTypeDropdownOpen(false);
        setIsProfileDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleIsochroneTypeChange = (newType) => {
    setIsTypeDropdownOpen(false);
    onIsochroneChange(location.uid, newType, isochroneSettings.value, isochroneSettings.enabled, isochroneSettings.profile);
  };

  const handleIsochroneValueChange = ({ x }) => {
    const newValue = isochroneSettings.type === 'time' ? Math.min(x, 60) : Math.min(x, 100);
    onIsochroneChange(location.uid, isochroneSettings.type, newValue, isochroneSettings.enabled, isochroneSettings.profile);
  };

  const handleIsochroneToggle = (e) => {
    const newEnabledState = e.target.checked;
    onIsochroneChange(location.uid, isochroneSettings.type, isochroneSettings.value, newEnabledState, isochroneSettings.profile);
  };

  const handleProfileChange = (newProfile) => {
    setIsProfileDropdownOpen(false);
    onIsochroneChange(location.uid, isochroneSettings.type, isochroneSettings.value, isochroneSettings.enabled, newProfile);
  };

  const {
    uid,
    latitude,
    longitude,
    altitude,
    batterylevel,
    nbsatellites,
    speed,
    bearing,
    timestamp
  } = location;

  const formatLocalTime = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleString();
  };

  return (
    <div ref={menuRef} style={{
      fontSize: '9px',
      fontWeight: 'bold',
      width: '150px',
      position: 'absolute',
      top: '-500%',
      left: '1000%',
      transform: `translateX(-50%) scale(${isVisible ? 1 : 0.8})`,
      opacity: isVisible ? 0.8 : 0,
      backgroundColor: 'white',
      padding: '5px',
      borderRadius: '5px',
      boxShadow: '0 2px 10px rgba(0,0,0,0.2)',
      zIndex: 1000,
      transition: 'all 0.4s ease-in-out',
      lineHeight: '1.2',
      overflow: 'hidden',

    }}>
      <button onClick={onClose} style={{ float: 'right', padding: '0 2px', marginLeft: '2px' }}>x</button>
      <h3 style={{ margin: '0 0 2px 0', fontSize: '11px' }}>{uid}</h3>
      <div>Loc: {latitude.toFixed(4)}, {longitude.toFixed(4)}</div>
      <div>Alt: {altitude.toFixed(1)}m</div>
      <div>Batt: {batterylevel}%</div>
      <div>Sat: {nbsatellites}</div>
      <div>Spd: {speed.toFixed(2)} m/s</div>
      <div>Bng: {bearing.toFixed(1)}°</div>
      <div>Time: {formatLocalTime(timestamp)}</div>

      <div style={{ marginTop: '10px' }}>
        <label style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
          Isochrone:
          <ToggleSwitch
            id={`isochrone-toggle-${location.uid}`}
            checked={isochroneSettings.enabled}
            onChange={handleIsochroneToggle}
          />
        </label>
      </div>

      {isochroneSettings.enabled && (
        <>
          <div style={{ marginTop: '5px', position: 'relative' }}>
            <button
              onClick={(e) => {
                e.stopPropagation();
                setIsProfileDropdownOpen(!isProfileDropdownOpen);
              }}
              style={{ width: '100%', textAlign: 'left', fontSize: '9px' }}
            >
              {isochroneSettings.profile.charAt(0).toUpperCase() + isochroneSettings.profile.slice(1)}
            </button>
            {isProfileDropdownOpen && (
              <div style={{
                position: 'absolute',
                top: '100%',
                left: 0,
                backgroundColor: 'white',
                border: '1px solid #ccc',
                borderRadius: '3px',
                zIndex: 1001,
                width: '100%',
              }}>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleProfileChange('walking');
                  }}
                  style={{ display: 'block', width: '100%', textAlign: 'left', fontSize: '9px' }}
                >
                  Walking
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleProfileChange('cycling');
                  }}
                  style={{ display: 'block', width: '100%', textAlign: 'left', fontSize: '9px' }}
                >
                  Cycling
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleProfileChange('driving');
                  }}
                  style={{ display: 'block', width: '100%', textAlign: 'left', fontSize: '9px' }}
                >
                  Driving
                </button>
              </div>
            )}
          </div>

          <div style={{ marginTop: '5px', position: 'relative' }}>
            <button
              onClick={(e) => {
                e.stopPropagation();
                setIsTypeDropdownOpen(!isTypeDropdownOpen);
              }}
              style={{ width: '100%', textAlign: 'left', fontSize: '9px' }}
            >
              {isochroneSettings.type === 'time' ? 'Time' : 'Distance'}
            </button>
            {isTypeDropdownOpen && (
              <div style={{
                position: 'absolute',
                top: '100%',
                left: 0,
                backgroundColor: 'white',
                border: '1px solid #ccc',
                borderRadius: '3px',
                zIndex: 1001,
                width: '100%',
              }}>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleIsochroneTypeChange('time');
                  }}
                  style={{ display: 'block', width: '100%', textAlign: 'left', fontSize: '9px' }}
                >
                  Time
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleIsochroneTypeChange('distance');
                  }}
                  style={{ display: 'block', width: '100%', textAlign: 'left', fontSize: '9px' }}
                >
                  Distance
                </button>
              </div>
            )}
          </div>

          <div style={{ marginTop: '5px' }}>
            <label style={{ display: 'block', marginBottom: '5px' }}>
              {isochroneSettings.type === 'time' ? 'Minutes' : 'Kilometers'}:
              <Slider
                axis="x"
                x={isochroneSettings.value}
                xmin={1}
                xmax={isochroneSettings.type === 'time' ? 60 : 100}
                onChange={handleIsochroneValueChange}
                styles={{
                  track: { backgroundColor: '#ddd', height: '4px' },
                  active: { backgroundColor: '#5c9ded' },
                  thumb: { width: '10px', height: '10px' },
                }}
              />
              <span style={{ marginLeft: '5px' }}>
                {isochroneSettings.value} {isochroneSettings.type === 'time' ? 'min' : 'km'}
              </span>
            </label>
          </div>
        </>
      )}
    </div>
  );
};

export default Menu;
